var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('tbody',{staticClass:"invoice-body"},[_c('tr',[_c('td',[_c('a',{staticClass:"c-cyan",on:{"click":function($event){$event.preventDefault();return _vm.downloadPdf($event)}}},[_vm._v(_vm._s(_vm.invoice.number))])]),_vm._v(" "),_c('td',[_vm._v(_vm._s(_vm.invoice.name))]),_vm._v(" "),_c('td',[_vm._v(_vm._s(_vm._f("date")(_vm.invoice.date_invoice,"dd-MM-yyyy")))]),_vm._v(" "),(_vm.invoice.orders.length === 1)?_c('td',[_c('router-link',{staticClass:"c-cyan",attrs:{"to":{
          name: 'order',
          params: { orderId: _vm.invoice.orders[0].id },
          query: { hash: _vm.invoice.orders[0].hash }
        }}},[_vm._v("\n        "+_vm._s(_vm.invoice.orders[0].id)+"\n      ")])],1):_c('td',{staticClass:"invoice-combination",on:{"click":function($event){_vm.collapsed = !_vm.collapsed}}},[_vm._v(_vm._s(_vm.$t('invoice.combination'))+" "),_c('span',[_vm._v("+")])]),_vm._v(" "),_c('td',[_vm._v("\n      "+_vm._s(_vm._f("currency")(_vm.invoice.amount_untaxed,"€", 2, {thousandsSeparator: ".", decimalSeparator: ","}))+"\n    ")]),_vm._v(" "),_c('td',[_vm._v("\n      "+_vm._s(_vm._f("currency")(_vm.invoice.amount_total,"€", 2, {thousandsSeparator: ".", decimalSeparator: ","}))+"\n    ")]),_vm._v(" "),_c('td',[_vm._v("\n      "+_vm._s(_vm.$t('invoice.'+_vm.invoice.state))+"\n    ")])]),_vm._v(" "),(!_vm.collapsed)?_vm._l((_vm.invoice.orders),function(order){return _c('tr',{key:order.id,staticClass:"combination-row"},[_c('td'),_vm._v(" "),_c('td',[_vm._v(_vm._s(order.projectreferentie))]),_vm._v(" "),_c('td',[_vm._v(_vm._s(_vm._f("date")(order.datumtijdorder,"dd-MM-yyyy")))]),_vm._v(" "),_c('td',[_c('router-link',{staticClass:"c-cyan",attrs:{"to":{
            name: 'order',
            params: { orderId: order.id },
            query: { hash: order.hash }
          }}},[_vm._v("\n          "+_vm._s(order.id)+"\n        ")])],1),_vm._v(" "),_c('td'),_vm._v(" "),_c('td')])}):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }