<template>
  <tbody class="invoice-body">
    <tr>
      <td>
        <a @click.prevent="downloadPdf" class="c-cyan">{{ invoice.number }}</a>
      </td>
      <td>{{ invoice.name }}</td>
      <td>{{ invoice.date_invoice | date("dd-MM-yyyy") }}</td>
      <td v-if="invoice.orders.length === 1">
        <router-link
          class="c-cyan"
          :to="{
            name: 'order',
            params: { orderId: invoice.orders[0].id },
            query: { hash: invoice.orders[0].hash }
          }"
        >
          {{ invoice.orders[0].id }}
        </router-link>
      </td>
      <td class="invoice-combination" v-else @click="collapsed = !collapsed">{{ $t('invoice.combination') }} <span>+</span></td>
      <td>
        {{ invoice.amount_untaxed | currency("€", 2, {thousandsSeparator: ".", decimalSeparator: ","}) }}
      </td>
      <td>
        {{ invoice.amount_total | currency("€", 2, {thousandsSeparator: ".", decimalSeparator: ","}) }}
      </td>
      <td>
        {{ $t('invoice.'+invoice.state) }}
      </td>
    </tr>
    <template v-if="!collapsed">
      <tr v-for="order in invoice.orders" :key="order.id" class="combination-row">
        <td></td>
        <td>{{ order.projectreferentie }}</td>
        <td>{{ order.datumtijdorder | date("dd-MM-yyyy")}}</td>
        <td>
          <router-link
            class="c-cyan"
            :to="{
              name: 'order',
              params: { orderId: order.id },
              query: { hash: order.hash }
            }"
          >
            {{ order.id }}
          </router-link>
        </td>
        <td></td>
        <td></td>
      </tr>
    </template>
  </tbody>
</template>
<script>
import currency from "../../utilities/filters/currency";
export default {
  props: {
    invoice: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      collapsed: true
    }
  },
  methods: {
    downloadPdf() {
      this.$http({
        url: `/invoices/${this.invoice.number}`,
        method: 'GET',
        responseType: 'blob',
      }).then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${this.invoice.number}.pdf`);
        document.body.appendChild(link);
        link.click();
      })
    }
  },
  filters: {
    currency
  }
}
</script>
<style lang="scss">
  @import "~vars";

  .invoice-combination {
    cursor: pointer;
  }

  .invoice-body {
    border-bottom: $table-cell-border;
  }

  .table.is-striped tbody.invoice-body:nth-child(odd) {
    background-color: $table-striped-row-even-background-color;
  }

  .invoice-body .combination-row {
    background-color: rgba($grey-darker, 0.15) !important;
  }
</style>
