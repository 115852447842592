<template>
  <div>
    <div class="card mobile invoiceCard" v-for="invoice in invoices" :key="invoice.id">
      <router-link
        :to="{
          name: 'order',
          params: { orderId: invoice.orders[0].id },
          query: { hash: invoice.orders[0].hash }
        }"
      >
        <div class="card-content">
          <div class="content">
            <div class="columns">
              <div class="column">
                <div class="title">
                  {{ invoice.name }} {{ invoice.number }}
                  <span class="tag is-warning" v-if="invoice.state === 'open'">Open</span>
                  <span class="tag is-success" v-if="invoice.state === 'paid'">Betaald</span>

                </div>
              </div>
            </div>
            <!-- <div class="columns">
                <div class="column">
                {{ invoice.date_invoice | date("dd-MM-yyyy") }}
                </div>
                <div class="column">
                {{ invoice.orders.length === 1 ? invoice.orders[0].id : $t('invoice.combination') }}
                </div>
            </div> -->
            <div class="subtitle">
              <div class="leftbox">
                <p class="c-cyan">{{invoice.orders[0].id}}</p>
              </div>
              <div class="rightbox">
                <p><b>Exclusief BTW:</b>{{ invoice.amount_untaxed | currency("€", 2, {thousandsSeparator: ".", decimalSeparator: ","}) }}</p>
                <p><b>Totaal:</b> {{ invoice.amount_total | currency("€", 2, {thousandsSeparator: ".", decimalSeparator: ","}) }}</p>
              </div>
            </div>
            <a @click.prevent="downloadPdf(invoice)" class="button" style="width:100%;">Download factuur</a>
          </div>
        </div>
      </router-link>
    </div>
  </div>
</template>
<script>
import currency from "../../utilities/filters/currency";
export default {
    props: {
        invoices: {
            type: Array,
            required: true,
        },
    },
    methods: {
        downloadPdf(invoice) {
            this.$http({
            url: `/invoices/${invoice.number}`,
            method: 'GET',
            responseType: 'blob',
            }).then(response => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `${invoice.number}.pdf`);
                document.body.appendChild(link);
                link.click();
            })
            }
    },
    filters: {
    currency
  }
}
</script>
<style lang="scss" scoped>
  .title{
    margin: 0.5rem;
  }
    .tag{
        //top right of the card
        position: absolute;
        top: 0;
        right: 0;
    }

    .invoiceCard{
        margin-bottom: 1rem;
    }
    .rightbox{
        text-align: right;
        b{
            margin-right: 1rem;
        }
    }
</style>