<template>
  <div class="columns is-multiline">
    <div class="column is-2">
      <div class="field" v-if="page_text !== 'Geen toegang'">
        <label class="label">{{ $t('ui.search') }}</label>
        <input
          type="text"
          class="input"
          @input="setReference"
          :value="reference"
          :placeholder="$t('invoice.number')"
        />
      </div>
    </div>
    <div class="column" v-if="invoices.length > 0">
      <div v-if="!el.is['is-small']">
        <table class="table is-fullwidth is-hoverable is-striped">
          <thead>
            <tr>
              <th v-for="head in headers" :key="head">{{ $t('invoice.' + head) }}</th>
            </tr>
          </thead>
          <template v-for="invoice in filteredInvoices" >
            <invoice-row :invoice="invoice" :key="invoice.id"></invoice-row>
          </template>
        </table>
        <button
          v-if="more"
          class="button is-text"
          style="font-weight: bold"
          @click="retrieveData(reference, current)"
        >
          {{ $t('overview.more') }}
        </button>
      </div>
      <div v-else>
        <invoices-mobile :invoices="filteredInvoices"></invoices-mobile>
      </div>
    </div>
    <div v-else>
      <h1 class="title">{{ page_text }}</h1>
    </div>
  </div>
</template>
<script>
import InvoiceRow from "../components/Invoices/Invoice";
import debounce from "lodash-es/debounce";
import responsiveMixin from "../utilities/responsiveMixin";
import InvoicesMobile from "./Printer/InvoicesMobile";
export default {
  data() {
    return {
      invoices: [],
      reference: null,
      current: 0,
      more: true,
      page_text: this.$tc('overview.none', this.$tc('overview.invoices')),
      headers: [
        'number',
        'name',
        'date_invoice',
        'orders',
        'amount_untaxed',
        'amount_total',
        'state'
      ]
    }
  },
  mixins: [responsiveMixin],
  methods: {
    setReference: debounce(function(event) {
      this.reference = event.target.value !== "" ? event.target.value : null;
    }, 500),
    async retrieveData(reference = null, current = 0) {
      const query = reference ? `q=${reference}` : null;
      const offset = `offset=${current}`;
      const parameters = `?${query ? query + `&${offset}` : offset}`

      try {
        const { data } = await this.$http.get(`/invoices${parameters}`);
        if (data.length > 0) {
          this.current = this.current + data.length;
          this.invoices.push(...data);
        }
        if (data.length < 10) {
          this.more = false;
        }
      } catch (e) {
        this.page_text = "Geen toegang";
      }
    }
  },
  computed: {
    filteredInvoices() {
      if (this.reference) {
        return this.invoices.filter(invoice => invoice.number.includes(this.reference));
      }
      return this.invoices;
    }
  },
  watch: {
    reference(val) {
      if (val && !this.invoices.find(invoice => invoice.number.includes(this.reference))) {
        this.retrieveData(val, 0);
      }
    }
  },
  created() {
    this.retrieveData();
  },
  components: {
    InvoiceRow,
    InvoicesMobile
  }
}
</script>
